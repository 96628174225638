import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

// Style imports
import './styles/project-card.scss'

const ProjectCard = ({ project, projectSlugs, classes = '' }) => {
  const {
    frontmatter: { slug, companyLogo, title, summary },
  } = project.node

  return (
    <div className={`project-card scroll-animation ${classes}`} key={project.title}>
      {/* eslint-disable global-require */}
      <Link to={`/projects/${slug}`} title="Slice It">
        <div className="project-image-container square">
          <img src={companyLogo} className="project-image" alt="logo of project assosciated company" />
        </div>
      </Link>
      <div className="project-card-content">
        <h3 className="project-card-title header-3">{title}</h3>
        <p className="text">{summary}</p>
      </div>
    </div>
  )
}

ProjectCard.propTypes = {
  project: PropTypes.object,
  projectSlugs: PropTypes.array,
}

export default ProjectCard
