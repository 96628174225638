import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

// Style imports
import './styles/project-list.css'

// Component imports
import ProjectCard from './project-card'

// Helper imports
import { isEven } from '../../utils/helpers/numbers'

const ProjectList = props => {
  const [projectSlugs, setProjectSlugs] = useState([])
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            id
            frontmatter {
              title
              summary
              companyLogo
              slug
            }
          }
        }
      }
    }
  `)

  const {
    allMarkdownRemark: { edges: projects },
  } = data

  useEffect(() => {
    // Get all slugs
    setProjectSlugs(projects.map(project => `/projects/${project.node.frontmatter.slug}`))
  }, [])

  return (
    <div className="project-list">
      {projects.map((project, idx) => (
        <ProjectCard
          project={project}
          projectSlugs={projectSlugs}
          key={idx}
          classes={isEven(idx + 1) ? 'in-from-right' : 'in-from-left'}
        />
      ))}
    </div>
  )
}

ProjectList.propTypes = {}

export default ProjectList
