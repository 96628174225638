import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

// JS Library imports
import axios from 'axios'

// Style imports
import './styles/contact-form.scss'

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
}

const SuccessScreen = ({ closeModal, setFormSubmitted }) => (
  // Action: Display a success message on form submit
  // Following action: Close modal after animation ends
  <h2
    className="success-message header-2"
    onAnimationEnd={() =>
      setTimeout(() => {
        closeModal()
        setTimeout(() => setFormSubmitted(false), 500)
      }, 2000)
    }
  >
    Thanks for saying hi! I'll get back to you through email soon.
  </h2>
)

const ContactForm = ({ closeModal, showForm = false }) => {
  const formRef = useRef(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [formFields, updateFormFields] = useState({ name: '', email: '', message: '', botField: '' })
  const [formSubmitted, setFormSubmitted] = useState(false)

  useEffect(() => {
    setModalIsOpen(showForm)
  }, [showForm])

  const handleClick = e => {
    if (!formRef.current.contains(e.target)) {
      closeModal()
    }
  }

  const closeIt = () => console.log('closing')

  const handleSubmit = e => {
    axios
      .post(
        '/',
        encode({
          'form-name': 'contact',
          ...formFields,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then(() => setFormSubmitted(true))
      .catch(() => console.log('epic fail'))

    e.preventDefault()
  }

  const handleChange = (e, property) => {
    const form = { ...formFields }

    console.log('handling change')

    form[property] = e.target.value

    updateFormFields(form)
  }

  return (
    <div className={`contact-form-modal ${modalIsOpen ? 'open' : 'closed'}`} onClick={handleClick}>
      {formSubmitted ? (
        <SuccessScreen closeModal={closeModal} setFormSubmitted={setFormSubmitted} />
      ) : (
        <form
          className="contact-form"
          onSubmit={handleSubmit}
          ref={formRef}
          name="contact"
          method="POST"
          action="/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <span onClick={closeModal} className="contact-form-close-button">
            X
          </span>
          <h2 className="header-2">Contact</h2>
          <input name="form-name" value="contact" type="hidden" />
          <input name="bot-field" onChange={handleChange} type="hidden" />
          <p>
            <label htmlFor="contact-name-input">
              <input
                type="text"
                name="name"
                id="contact-name-input"
                onChange={e => handleChange(e, 'name')}
                placeholder="Name"
                className="form-field"
              />
            </label>
          </p>
          <p>
            <label htmlFor="contact-email-input">
              <input
                type="email"
                name="email"
                id="contact-email-input"
                onChange={e => handleChange(e, 'email')}
                placeholder="Email"
                className="form-field"
              />
            </label>
          </p>
          <p>
            <label htmlFor="contact-form-message">
              <textarea
                name="message"
                id="contact-form-message"
                onChange={e => handleChange(e, 'message')}
                placeholder="Message"
                className="form-field text-area"
              />
            </label>
          </p>
          <button type="submit" className="button">
            Send
          </button>
        </form>
      )}
    </div>
  )
}

ContactForm.propTypes = {
  closeModal: PropTypes.func,
  showForm: PropTypes.bool,
}

export default ContactForm
