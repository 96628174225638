import React from 'react'
import PropTypes from 'prop-types'

// Style imports
import './styles/skill-card.scss'

// Component imports
import SkillCard from './skill-card'

// Media imports
import ComputerIcon from '../../../assets/svgs/icons/ic-computer.svg'
import FlowerIcon from '../../../assets/svgs/icons/ic-flowers.svg'
import PeopleIcon from '../../../assets/svgs/icons/ic-people.svg'
import RouterIcon from '../../../assets/svgs/icons/ic-router.svg'

const SkillCardList = props => (
  <div className="skill-card-list-container scroll-animation in-from-right">
    <h2 className="skill-card-list-header header-2">I am skilled in</h2>
    <div className="skill-card-list">
      <SkillCard
        icon={<ComputerIcon />}
        title="Front-end"
        description="I love making designs come to life through well documented and testable code. Front-end is more than cool animations!"
      />
      <SkillCard
        icon={<RouterIcon />}
        title="Back-end"
        description="I build servers for maintainability and scale. It is important to me that others can understand my work and that my code is of high quality."
      />
      <SkillCard
        icon={<FlowerIcon />}
        title="Web design"
        description="Using best practices and paying attention to the users need in order to create useable and attractive designs."
      />
      <SkillCard
        icon={<PeopleIcon />}
        title="Teamwork"
        description="I'm always open to feedback and new ways that can help improve my self and the people around me. I take ownership and pride in my team's work."
      />
    </div>
  </div>
)

SkillCardList.propTypes = {}

export default SkillCardList
