import React from 'react'

// Style imports
import './styles/project-section.css'

// Component imports
import ProjectList from './project-list'

// Media imports
import DividerTop from '../../../assets/svgs/dividers/projects-top.svg'
import DividerBot from '../../../assets/svgs/dividers/projects-bot.svg'

const ProjectSection = () => (
  <section className="project-section" id="work">
    <DividerTop />
    <div className="project-section-inner container">
      <h2 className="header-2 center-text project-section-header">Work</h2>
      <ProjectList />
    </div>
    <DividerBot />
  </section>
)

export default ProjectSection
