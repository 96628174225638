import React from 'react'
import PropTypes from 'prop-types'

// Style imports
import './styles/skill-section.scss'

// Component imports
import SkillCardList from './skill-card-list'

// Media imports
import BrainArt from '../../../assets/svgs/artwork/brains.svg'

const SkillSection = props => (
  <section className="skill-section container" id="about">
    <div className="brain-art-container scroll-animation in-from-left">
      <BrainArt />
    </div>
    <SkillCardList />
  </section>
)

SkillSection.propTypes = {}

export default SkillSection
