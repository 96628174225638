import React from 'react'
import PropTypes from 'prop-types'
import './styles/skill-card.scss'

const SkillCard = ({ icon, title, description }) => (
  <div className="skill-card">
    <div className="skill-icon-circle">{icon}</div>
    <h3 className="header-3">{title}</h3>
    <p className="text">{description}</p>
  </div>
)

SkillCard.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default SkillCard
