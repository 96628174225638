import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Component imports
import ContactForm from './contact-form'

// Style imports
import './styles/contact-section.scss'

const ContactSection = props => {
  const [showForm, setShowForm] = useState(false)

  return (
    <section className="contact-section container" id="contact">
      <div className="contact-card scroll-animation fade-in">
        <h2 className="header-2">Like what you see?</h2>
        <p className="text">
          Want a new website, or are you curious if I am currently looking for new opportunities? I would love to chat
          with you!
        </p>
        <button className="button load-animation shake-it" onClick={() => setShowForm(true)}>
          Contact Me
        </button>
      </div>
      <ContactForm closeModal={() => setShowForm(false)} showForm={showForm} />
    </section>
  )
}

ContactSection.propTypes = {}

export default ContactSection
