import React, { useEffect } from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import Layout from '../components/layout'
import SEO from '../components/seo'

// JS libraries and polyfills

// Style imports
import '../styles/main.scss'
import '../styles/index-page.scss'
import '../components/shared/styles/animations.css'

// Component imports
import SkillSection from '../components/about/skill-section'
import ProjectSection from '../components/projects/project-section'

// Library imports
import transitionAnimations from '../utils/transition-animations'

// Media imports
import SplashDivider from '../../assets/svgs/dividers/splash-bot.svg'
import BinaryVideo from '../../assets/videos/binary.mp4'
import ContactSection from '../components/contact/contact-section'

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <div className="splash-container container">
      <div className="splash-message load-animation fade-in">
        <h1 className="header-1 no-margin-top">
          Hello <br /> My name is Hans. <br />
          I'm a web developer
        </h1>
        <p className="text">
          I live and breath code and have a interest in great design. Let’s strike up a conversation about websites or
          job opportunities. Bring the coffee and I am all yours for a chat.
        </p>
        <button className="button load-animation shake-it" onClick={() => scrollTo('#work')}>
          See work
        </button>
      </div>
      <div className="splash-video load-animation fade-in">
        <svg viewBox="0 0 651 472" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-laptop">
          <rect x="138.405" width="511.609" height="328.066" rx="12.3052" fill="black" />
          <clipPath id="splash-video-clip">
            <rect
              x="154.787"
              y="29.1958"
              width="479.65"
              height="281.254"
              fill="#323232"
              className="splash-video-container"
            />
          </clipPath>
          <foreignObject x="154.787" y="29.1958" width="479.65" height="281.254">
            <video xmlns="http://www.w3.org/1999/xhtml" autoPlay loop muted playsInline height="100%" width="100%">
              <source src={BinaryVideo} type="video/mp4" />
              <track kind="captions" src="../media/videos/captions/binary-captions-en.vtt" />
              Your browser does not support the video tag.
            </video>
          </foreignObject>
          <path
            d="M138.492 329.483C139.433 328.514 140.727 327.967 142.078 327.967H639.986C644.057 327.967 646.422 332.573 644.049 335.881L547.926 469.914C546.987 471.223 545.474 472 543.863 472H11.8307C7.41063 472 5.1645 466.685 8.24487 463.515L138.492 329.483Z"
            fill="#707070"
          />
          <path d="M162.862 336.726H611.506L597 353H147.5L162.862 336.726Z" fill="black" />
          <ellipse cx="394.612" cy="17.6783" rx="3.86643" ry="3.91959" fill="#646464" fillOpacity="0.8" />
        </svg>
      </div>
    </div>
    <SplashDivider alt="splash container divider with background" className="divider" />

    <SkillSection />

    <ProjectSection />

    <ContactSection />
  </Layout>
)

export default IndexPage
